<template>
    <div id="study" class="study" :class="mode === 'phone' ? 'mobile-study' : ''">
        <div class="container" v-if="!loading">
            <VideoModule :mode="mode" @playEnd="onVideoEnded" :currentCourse="currentCourse" />
            <div
                class="course-btn"
                v-if="mode === 'computer'"
                @click="isShowMenu = !isShowMenu"
                :class="isShowMenu ? '' : 'right-course-btn'"
            >
                <van-icon v-show="!isShowMenu" name="arrow-left" />
                <van-icon v-show="isShowMenu" name="arrow" />
            </div>
            <div class="course-container" :class="isShowMenu ? '' : 'hide-course-container'">
                <div class="title">
                    <div
                        @click="onSwitchTab(item.val)"
                        :class="activeTab === item.val ? 'active-title' : ''"
                        v-for="item in tabItems"
                        :key="item.val"
                    >
                        {{ item.n }}
                    </div>
                </div>
                <ListModule
                    class="list-module"
                    @initTab="onSwitchTab"
                    @courseChange="onCourseChange"
                    :currentCourse="currentCourse"
                    :activeTab="activeTab"
                    :listData="listData"
                    :endedKey="endedKey"
                />
            </div>
        </div>
        <Watermark />
        <AgmAffirm :isShow.sync="isShowAgmAffirm" :agmInfo="agmInfo" />
    </div>
</template>
<script>
import Watermark from '@/components/Watermark'
import VideoModule from './components/VideoModule.vue'
import ListModule from './components/ListModule.vue'
import AgmAffirm from './components/AgmAffirm.vue'
import { getPackageStage } from '@/api/studyApi.js'
import { getAgreementStates, getOrderPackage } from '@/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'study',
    components: { VideoModule, ListModule, Watermark, AgmAffirm },
    data() {
        return {
            tabItems: [],
            productDetail: null,
            listData: [],
            activeTab: '',
            currentCourse: null,
            endedKey: 1,

            isShowMenu: true,
            loading: true,
            mode: 'computer',

            agmInfo: null,
            isShowAgmAffirm: false,
        }
    },
    computed: { ...mapGetters(['userInfo', 'deviceInfo']) },
    mounted() {
        this.ininPackageData()
        if (this.deviceInfo.mobile) {
            if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
                this.mode = 'phone'
            }
        }
        window.onresize = () => {
            this.mode = 'computer'
            if (this.deviceInfo.mobile) {
                if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
                    this.mode = 'phone'
                }
            }
        }
    },
    destroyed() {
        window.onresize = null
    },
    methods: {
        onSwitchTab(tab) {
            if (this.activeTab !== tab) {
                this.activeTab = tab
            }
        },
        onCourseChange(course) {
            this.currentCourse = course
            this.onVideoEnded()
        },
        onVideoEnded() {
            this.endedKey += 1
        },
        async ininPackageData() {
            // 获取产品包详情
            const res1 = await getOrderPackage({ orderNo: this.$route.params.orderNo })
            if (!res1.data || res1.data.length === 0) {
                this.$router.push('/course')
                return
            }
            const _tabMap = {
                PAID_VIDEO_STAGE: { name: '阶段课程', tabVal: 'stage', children: [], stageChildren: [] },
                PAID_LIVE_GIFT: { name: '直播课', tabVal: 'live', children: [] },
                PAID_VIDEO_GIFT: { name: '精品课', tabVal: 'video', children: [] },
            }
            res1.data.forEach((item) => {
                const _i = {
                    id: `menu_${item.packageNo}`,
                    packageNo: item.packageNo,
                    ptype: item.ptype,
                    title: item.title,
                    showTab: _tabMap[item.ptype]['tabVal'],
                    unlocked: true,
                }
                _tabMap[item.ptype]['children'].push(_i)
            })
            // 如果配置阶段，则获取对应阶段
            if (_tabMap['PAID_VIDEO_STAGE']['children'].length !== 0) {
                const _stageInfo = _tabMap['PAID_VIDEO_STAGE']['children']
                const res2 = await getPackageStage({ packageNo: _stageInfo.packageNo }).catch(() => {
                    return { data: [] }
                })
                res2.data.forEach((item) => {
                    const _stage = {
                        id: `menu${item.packageNo}`,
                        packageNo: _stageInfo.packageNo,
                        ptype: _stageInfo.ptype,
                        showTab: _stageInfo.showTab,
                        title: item.title,
                        unlocked: item.unlocked,
                        stageId: item.id,
                    }
                    _tabMap['PAID_VIDEO_STAGE']['stageChildren'].push(_stage)
                })
            }
            const _listData = []
            const _tabItems = []
            Object.values(_tabMap).forEach((i) => {
                if (i.tabVal === 'stage') {
                    _listData.push(...i.stageChildren)
                } else {
                    _listData.push(...i.children)
                }
                if (i.children.length !== 0) {
                    _tabItems.push({ n: i.name, val: i.tabVal })
                }
            })
            this.listData = _listData
            this.tabItems = _tabItems
            this.loading = false
            // 身份证验证
            this.getAgreementStatesApi()
        },
        getAgreementStatesApi() {
            getAgreementStates({ orderNos: [this.$route.params.orderNo] }).then((res) => {
                if (res.data[0] && res.data[0]['needCertNo']) {
                    this.agmInfo = { ...res.data[0], orderNo: this.$route.params.orderNo }
                    this.isShowAgmAffirm = res.data[0]['needCertNo']
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    width: 100vw;
    height: 100vh;
}
.course-btn {
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 318px;
    transform: translateY(-50%);
    width: 16px;
    height: 84px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px 20px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &.right-course-btn {
        right: 0;
        border-radius: 20px 0 0 20px;
        background: rgba(0, 0, 0, 0.3);
    }
}
.course-container {
    background-color: #37393d;
    width: 334px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    &.hide-course-container {
        width: 0;
    }
    .title {
        width: 334px;
        height: 64px;
        background: rgba(0, 0, 0, 4);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #262930;
        > div {
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ccc;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
        }
        .active-title {
            color: #f0941d;
        }
    }
}

// 移动端适配
.mobile-study {
    .container {
        flex-direction: column;
    }
    .video-container {
        height: calc(56.25vw + 96px);
    }
    .course-container {
        width: 100%;
        height: calc(100vh - 56.25vw - 96px);
        min-height: 300px;
        .title {
            width: 100%;
            height: 42px;
        }
    }
    .list-module {
        top: 58px;
        left: 16px;
        bottom: 16px;
        right: 16px;
    }
}
.canvas {
    background: #37393d;
}
</style>
